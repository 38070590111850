@import "_include/_color.sass";
@import "_include/_media.sass";
@import "_include/_mixin.sass";

[class*="fade"].kaf_out {
  opacity: 1;
  @keyframes fadeOut {
    0% {
      opacity: 1; }
    100% {
      opacity: 0; } }
  animation-name: fadeOut;
  animation-fill-mode: both; }

.fade {
  opacity: 0;
  &.kaf_active {
    @keyframes fade {
      0% {
        opacity: 0; }
      100% {
        opacity: 1; } }
    animation-name: fade;
    animation-fill-mode: both; } }

.fadeDown {
  opacity: 0;
  &.kaf_active {
    @keyframes fadeDown {
      0% {
        transform: translateY(-100px);
        opacity: 0; }
      100% {
        opacity: 1;
        transform: translateY(0); } }
    animation-name: fadeDown;
    animation-fill-mode: both; } }

.fadeUp {
  opacity: 0;
  &.kaf_active {
    @keyframes fadeUp {
      0% {
        transform: translateY(100px);
        opacity: 0; }
      100% {
        opacity: 1;
        transform: translateY(0); } }
    animation-name: fadeUp;
    animation-fill-mode: both; } }

.fadeLeft {
  opacity: 0;
  &.kaf_active {
    @keyframes fadeLeft {
      0% {
        transform: translateX(-50px);
        opacity: 0; }
      100% {
        opacity: 1;
        transform: translateX(0); } }
    animation-name: fadeLeft;
    animation-fill-mode: both; } }

.fadeRight {
  opacity: 0;
  &.kaf_active {
    @keyframes fadeRight {
      0% {
        transform: translateX(50px);
        opacity: 0; }
      100% {
        opacity: 1;
        transform: translateX(0); } }
    animation-name: fadeRight;
    animation-fill-mode: both; } }

.fadeScale {
  opacity: 0;
  &.kaf_active {
    @keyframes fadeScale {
      0% {
        transform: scale(0.9) translateY(10px);
        opacity: 0; }
      100% {
        opacity: 1;
        transform: scale(1) translateY(0px); } }
    animation-name: fadeScale;
    animation-fill-mode: both; } }




