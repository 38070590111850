
@mixin size($w,$h:$w) {
  width: $w;
  height: $h; }

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both; } }

@mixin t($t:0.5s) {
  transition-duration: $t; }

@mixin cr($c:#1a1a1a) {
  color: $c; }

@mixin border($w:1px,$s:solid,$c:#222) {
  border: $w $s $c; }

@mixin circle($r) {
  width: $r;
  height: $r;
  border-radius: 50%; }

@mixin font {
  @font-face {
    src: url("/assets/fonts/GenSekiGothicTW-Medium.ttf");
    font-family: GenSekiGothicTW; }
  font-family: GenSekiGothicTW; }

@mixin en {
  font-family: 'impact'; }
