@import "_include/_color.sass";
@import "_include/_media.sass";
@import "_include/_mixin.sass";

.kc-container {
  max-width: 100%;
  .kc_column, .kc_column_inner {
    padding: 0; } }


.sf-arrows .sf-with-ul {
  padding-right: 15px!important;
  &:after {
    display: none; } }

#header {
  min-height: 0; }

.main-nav-right #main-nav {
  margin-top: 0; }

#mobile-nav-toggle {
  position: absolute;
  margin: 0;
  top: 30px;
  right: 5%;
  color: #333; }

#mobile-nav ul .menu-item-has-children i {
  color: #333;
  width: 100%;
  height: 64px;
  &:before {
    position: absolute;
    right: 10px;
    bottom: 50%;
    transform: translate(0,50%); } }


html, body {
  background: none; }

#main {
  margin-top: 0; }

.wp-pagenavi {
  text-align: center;
  padding: 0 0 30px 0;
  span,a {
    display: inline-block;
    font-size: 14px;
    color: #333;
    padding: 5px 15px;
    margin: 0 2px;
    @include t(.3s); }
  span,a:hover {
    background-color: #333;
    color: #fff; } }

#main-nav-container,#main-nav {
  @include clearfix; }

.owl-item {
  img {
    display: block;
    width: 100%; } }
