@import "_include/_color.sass";
@import "_include/_media.sass";
@import "_include/_mixin.sass";

.f-t,.t-f {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 100px;
  @media #{$tablet-xs} {
    margin-bottom: 50px; }
  &:last-child {
    margin-bottom: 0; }
  figure {
    display: block;
    width: 55%;
    @media #{$tablet-xs} {
      width: 100%; }
    img {
      display: block;
      width: 100%; } }
  figcaption {
    display: block;
    margin-top: 15px;
    padding: 30px;
    width: 45%;
    background: $color_bg_linear;
    @media #{$tablet-xs} {
      width: 100%;
      margin-top: 80px; }
    h2 {
      background: $color_bg_linear;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 20px;
      position: absolute;
      bottom: 100%;
      left: 30px;
      font-weight: 500;
      letter-spacing: 3px;
      font-size: 22px;
      @include en;
      &:after {
        content: "";
        display: block;
        @include size(60px,2px);
        margin-top: 10px;
        background: #999; } }
    p,li {
      @include cr(#fff);
      font-size: 16px;
      letter-spacing: 1px;
      text-align: justify; }
    h3 {
      font-size: 22px;
      color: #fff;
      &:after {
        content: "";
        display: block;
        @include size(60px,2px);
        margin-top: 10px;
        background: #999; } }
    ul {
      padding: 20px 0 30px 0;
      &:last-of-type {
        padding-bottom: 0; } } } }

.t-f {
  flex-direction: row-reverse;
  @media #{$tablet-xs} {
    flex-direction: initial; } }

h2.page-title {
  font-size: 22px;
  color: $color_main;
  text-align: center;
  margin-bottom: 60px;
  &:after {
    content: "";
    display: table;
    @include size(40px,3px);
    background: $color_btn_hover;
    position: absolute;
    top: 35px;
    right: 50%;
    transform: translate(50%,50%); }
  .text {
    margin-top: 30px;
    p {
      color: $color_text;
      letter-spacing: 2px;
      line-height: 23px; } } }

.figure-group {
  margin-bottom: 70px;
  display: flex;
  flex-wrap: wrap;
  @media #{$phone} {
    margin-bottom: 40px; }
  &:last-child {
    margin-bottom: 0; }
  figure {
    display: block;
    width: calc(100% / 4 - 41px);
    margin: 0 20px;
    &:hover {
      a {
        opacity: 0.6; } }
    @media #{$phone} {
      width: calc(100% / 2 - 41px);
      margin-bottom: 20px; } }
  a {
    display: block;
    overflow: hidden;
    @include size(100%,0);
    padding-bottom: 150%;
    margin-bottom: 10px;
    box-shadow: 0 0 7px #666;
    @include t(.6s); }
  p,.content {
    color: $color_main;
    text-align: center;
    font-size: 16px; }
  .content {
    color: #333;
    font-size: 14px;
    padding-top: 5px; } }


.fancybox-navigation {
  position: initial; }

.fancybox-button--share {
  display: none; }

.gear-flex {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: solid 2px $color_main;
  figure {
    width: 35%;
    img {
      display: block;
      width: 100%; } }
  figcaption {
    padding: 30px;
    width: 65%;
    color: $color_text;
    h2 {
      margin-bottom: 15px;
      font-size: 1.3rem; } } }

//產品介紹
.menu {
  @media screen and (max-width: 768px) {
    position: fixed;
    left: -250px;
    top: 0px;
    @include size(250px,100%);
    padding-top: 51px;
    z-index: 100;
    background-color: rgba(255,255,255,0.8);
    @include t(.8s); }
  &.active {
    left: 0; }
  .m-toggle {
    display: none;
    position: absolute;
    left: 100%;
    top: 40%;
    background: $color_bg_linear;
    color: #fff;
    width: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    cursor: pointer;
    @media screen and (max-width: 768px) {
      display: block; } }
  .list {
    &>li {
      margin-bottom: 15px;
      @media screen and (max-width: 768px) {
        margin-bottom: 10px; }
      &>a {
        display: block;
        padding: 10px 15px;
        background: $color_bg_linear;
        @include cr(#fff);
        font-size: 14px;
        letter-spacing: 2px;
        text-decoration: none; }
      &.active>a+.secmenu {
        display: block; } } } }

.secmenu {
  padding: 10px 15px 20px 15px;
  background-color: #f2f2f2;
  display: none;
  li {
    a {
      display: block;
      padding: 5px 0;
      font-size: 14px;
      color: $color_text;
      border-bottom: solid 1px transparent;
      @include t(.5s);
      &:hover,&.active {
        border-bottom-color: $color_main; } } } }

.product-container {
  padding-left: 50px;
  @media (max-width: 768px) {
    padding-left: 30px;
    padding-right: 30px; }
  .head {
    background: $color_bg_linear;
    padding: 10px 15px;
    @include cr(#fff);
    letter-spacing: 3px;
    font-size: 22px;
    @media (max-width: 768px) {
      text-align: center; } }
  figure {
    display: flex;
    align-items: center;
    padding: 20px 0 40px 0;
    &:after {
      display: none;
      content: "scroll>>";
      position: absolute;
      bottom: 10px;
      right: 10px;
      letter-spacing: 2px;
      color: $color_main;
      animation: shake 2s infinite;
      @media #{$tablet} {
        display: block; }
      @keyframes shake {
        0% {
          transform: translateX(-5px); }
        50% {
          transform: translateX(5px); }
        100% {
          transform: translateX(-5px); } } }

    img {
      height: auto; }
    img:first-child {
      width: 60%; }
    img:last-child {
      width: 40%; } }
  .scroll-row {
    width: 100%;
    overflow-x: scroll; }
  table {
    width: 100%;
    td,th {
      width: 1%;
      text-align: center;
      min-width: 100px;
      &:first-of-type {
        min-width: 200px; } }
    th {
      background-color: #ddd;
      @include cr($color_text);
      letter-spacing: 2px;
      font-size: 16px;
      padding: 8px 0;
      &:nth-of-type(1) {
        width: 150px; } }
    td {
      padding: 8px 0;
      color: $color_text;
      font-family: 'Microsoft JhengHei'; }
    tbody {
      tr:nth-of-type(even) {
        background-color: #f2f2f2; }
      tr {
        @for $i from 2 through 4 {
          td:nth-of-type(#{$i}) {
            @include cr($color_main);
 } } } } } }            // opacity: 0.5


//最新消息
body.archive {
  .entry-content {
    display: none; }
  .post {
    width: calc(100% / 3 - 41px);
    margin: 0 20px 25px 20px;
    padding: 0 0 15px 0;
    border-bottom: solid 1px transparent;
    @include t(.5s);
    @media #{$tablet} {
      width: calc(100% / 2 - 41px); }
    @media #{$phone} {
      width: calc(100%);
      border-bottom-color: $color_main; }
    &:hover {
      border-bottom-color: $color_main; }
    .entry-header {
      margin-bottom: 0; }
    .featured-image {
      margin: 0 0 15px 0; }
    .date {
      display: block;
      margin-bottom: 10px;
      font-family: 'microsoft jhenghei';
      color: #666; }
    .txt {
      a {
        color: $color_text; } } }
  #main-container {
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 70px;
    &>.row>.col-md-12 {
      flex-wrap: wrap;
      display: flex; } }

  .wp-pagenavi {
    width: 100%; } }

body.single-post {
  .post {
    @media #{$phone} {
      padding: 20px; } }
  #main {
    padding-top: 100px;
    max-width: 1000px;
    margin: 0 auto; }
  .news-title {
    text-align: center;
    margin-bottom: 50px; }
  .date {
    font-size: 25px;
    margin-bottom: 15px;
    color: $color_main;
    @media #{$phone} {
      font-size: 18px; } }
  .txt {
    font-size: 30px;
    color: $color_text;
    font-weight: bold;
    @media #{$phone} {
      font-size: 23px; } }

  .entry-content {
    @include cr($color_text);
    p,a {
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 25px;
      @include cr($color_text); } }

  .single-post-navigation {
    display: none; } }


.map-flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: 0 auto 70px auto;
  &:last-child {
    margin-bottom: 0; }
  .map {
    width: 50%;
    padding: 0 20px;
    @media #{$phone} {
      width: 100%;
      margin-bottom: 30px; }
    iframe {
      width: 100%;
      height: 220px; } }
  .map-info {
    width: 50%;
    padding: 0 20px;
    @media #{$phone} {
      width: 100%; }
    h2 {
      display: table;
      font-size: 24px;
      margin-bottom: 20px;
      @include cr($color_text);
      &:after {
        content: "";
        display: block;
        margin-top: 10px;
        @include size(80%,2px);
        background-color: $color_main; } }
    p,a,span {
      font-size: 16px;
      color: $color_text;
      font-family: 'microsoft jhenghei'; }
    p {
      margin-bottom: 10px;
      &:last-child {
        margin: 0; } } } }

#contact-row {
  max-width: 1000px;
  padding: 0 20px;
  margin: 0 auto;
  padding-bottom: 100px;
  form>div {
    display: flex;
    flex-wrap: wrap; }
  &>div {
    max-width: 600px; }
  div.wpforms-container-full .wpforms-form .wpforms-field-label {
    color: $color_main;
    font-family: 'microsoft jhenghei'; }
  div.wpforms-container-full .wpforms-form .wpforms-field {
    width: 100%;
    &:nth-of-type(1),&:nth-of-type(2) {
      width: calc(50% - 15px); }
    &:nth-of-type(1) {
      margin-right: 30px; } }
  .wpforms-submit {
    background-color: $color_main;
    color: #fff;
    @media #{$phone} {
      margin: 0 auto; } } }
