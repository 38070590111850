@import "_include/_color.sass";
@import "_include/_media.sass";
@import "_include/_mixin.sass";

.main-nav-right .site-logo {
  margin-top: 0;
  padding: 12px 0;
  z-index: 10;
  a,img {
    @include size(242px,39px); }
  @media #{$tablet} {
    margin: 0;
    a,img {
      @include size(160px,25px); } } }

#mobile-nav-toggle {
  top: 15px; }

#header {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%,auto);
  z-index: 100; }

body.header-active {
  #header {
    position: fixed;
    animation: fadeDown 1s both;
    background: rgba(#fff,.8);
    z-index: 1000; }
  #mobile-nav-toggle {
    position: fixed;
    animation: fadeDown 1s both;
    z-index: 1000000; } }

#mobile-body-overly {
  z-index: 1000; }

#mobile-nav {
  z-index: 100000; }

#header-container {
  width: 95vw;
  max-width: 1280px; }

.main-nav-right #main-nav {
  &>li {
    margin-left: 1.5vw;
    &>a {
      text-align: center;
      .zh {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 2px;
        @include font;
        @include cr($color_btn_hover); }
      .en {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        @include font;
        @include cr(#666666); } }
    &:last-child {
      &>a .en {
        display: none; } } } }

#mobile-nav {
  ul {
    li {
      a {
        font-size: 16px;
        .zh,.en {
          font-size: 16px; }
        .zh {
          @include cr($color_btn_hover);
          font-weight: 500;
          @include font;
          letter-spacing: 3px; }
        .en {
          text-transform: uppercase;
          font-weight: 500;
          font-size: 12px;
          @include font;
          @include cr(#666); } } }
    &>li:last-child>a .en {
      display: none; } } }

#mobile-nav ul ul {
  padding: 10px 0;
  background-color: #f4f4f4;
  li {
    padding-left: 20px;
    a {
      color: #333333;
      font-weight: 500; } } }

#main-nav {
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translateY(50%); }

#main-nav>li>ul {
  right: 50%;
  left: initial;
  transform: translateX(50%); }

#main-nav ul {
  min-width: 128px;
  background-color: #fff;
  padding: 10px 0;
  @media #{$desktop} {
    padding: 0; }
  li {
    min-width: initial;
    background-color: initial;
    border: none;
    a {
      text-align: center;
      @include cr(#333);
      font-size: 14px;
      @include font;
      font-weight: 500; } } }

.contact-us.container {
  // background-color: #e19450
  margin-top: 100px;

  figure {
    width: 60%;
    @media #{$phone} {
      width: 100%; }
    img {
      display: block;
      width: 100%; } }
  .text {
    width: 40%;
    background: $color_bg_linear;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    @media #{$phone} {
      width: 100%;
      padding: 50px 0; }
    &:hover {
      a {
        background-color: #fff;
        opacity: 0.1!important;
        left: 0; } }
    a {
      display: block;
      position: absolute;
      top: 0;
      left: -100%;
      @include size(200%,100%);
      z-index: 5;
      @include t(1s);
      background: linear-gradient(to right, transparent 0%, #fff 20%,transparent 80%);
      opacity: 0.3; }
    h2 {
      display: block;
      text-align: center;
      @include cr(#fff);
      z-index: 2;
      text-transform: uppercase;
      .en {
        font-size: 36px;
        margin-bottom: 25px;
        @include en; }
      .zh {
        font-size: 18px;
        letter-spacing: 3px; }
      &:after {
        content: "";
        display: block;
        @include size(40px,1px);
        background-color: #fff;
        position: absolute;
        bottom: 40%;
        right: 50%;
        transform: translate(50%,50%); } } } }

.l-f {
  overflow: hidden;
  box-shadow: 3px 3px 10px #ccc;
  margin-bottom: 40px;
  @media #{$tablet} {
    align-items: flex-start; }
  &:last-of-type {
    margin-bottom: 0; }
  &:before,&:after {
    content: "";
    display: block;
    @include size(30%,0);
    padding-bottom: 30%;
    position: absolute;
    transform-origin: 50% 50%;
    transform: rotate(-45deg) translate(-50%,21%);
    @media #{$phone} {
      display: none; } }
  &:before {
    left: 22%;
    bottom: 50%;
    background-color: #fff;
    z-index: 3; }
  &:after {
    left: 25%;
    bottom: 50%;
    background: $color_bg_linear;
    box-shadow: 3px 3px 10px #222; }
  figure {
    padding-bottom: 30%;
    width: 65%;
    @media #{$tablet} {
      padding-bottom: 38%; }
    @media #{$phone} {
      width: 100%; } }
  .info {
    width: 35%;
    z-index: 4;
    background-color: #fff;
    padding: 0px 0px 0px 50px;
    @media #{$phone} {
      width: 100%;
      padding: 30px 40px; }
    p {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 1px; }
    a.btn.more {
      font-size: 12px;
      margin-top: 25px; } } }

.page-container.links {
  padding: 40px 30px;
  img {
    display: block;
    width: 100%; }
  .info {
    // display: none
    padding: 20px 0px;
    width: 100%;
    background-color: rgba(#000,.5);
    max-width: 330px;
    position: absolute;
    bottom: 50%;
    right: 50%;
    transform: translate(50%,50%);
    @media #{$phone} {
      display: block;
      background-color: rgba(#000,.2);
      bottom: 0;
      right: 0;
      @include size(100%);
      transform: initial;
      max-width: 100%; }
    a {
      display: block; }
    h2 {
      margin: {
        bottom: 0; }
      .en {
        margin-bottom: 30px;
        font-size: 36px;
        text-transform: uppercase; }
      .zh {
        font-size: 18px; }
      &:after {
        position: absolute;
        bottom: 42%;
        right: 50%;
        margin: 0;
        transform: translate(50%,50%); } } } }

#footer {
  background-color: #e2e2e2;
  padding-bottom: 60px;
  padding-top: 40px;
  .go-top {
    position: absolute;
    bottom: 100%;
    right: 0;
    @include size(40px,50px);
    background: $color_bg-linear;
    cursor: pointer;
    &:after {
      content: "";
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      @include size(100%);
      background-image: url('/wp-content/uploads/ic_top.svg');
      background-size: 20px 19px;
      background-repeat: no-repeat;
      background-position: center center; } }
  a.logo {
    max-width: 250px;
    margin: 0 auto;
    display: block; }
  p.copy,a {
    color: #4d4d4d;
    font-size: 12px;
    font-family: 'Microsoft JhengHei'; }
  p.copy {
    position: absolute;
    bottom: 5px;
    left: 0;
    text-align: center;
    width: 100%; } }
