@import "_include/_color.sass";
@import "_include/_media.sass";
@import "_include/_mixin.sass";

* {
  position: relative;
  vertical-align: middle;
  font-family: 'Microsoft JhengHei';
  font-size: 14px; }

// font-family: 'impact'

body {
  animation: fade 1s 2s both; }

h1,h2,h3,h4,h5,h6,p {
  margin: 0; }

ul {
  margin: 0;
  padding: 0;
  li {
    list-style-type: none; } }

// bg
[data-bg] {
  display: block;
  @include size(100%,auto);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }

.banner[data-bg] {
  height: 400px;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%);
    background-color: rgba(#000,.1); }
  @media #{$phone} {
    height: 250px; }
  h1 {
    display: table;
    position: absolute;
    right: 50%;
    bottom: 50%;
    transform: translate(50%,50%);
    color: #fff;
    letter-spacing: 3px;
    z-index: 2;
    @media #{$phone} {
      font-size: 25px; }
    &:after {
      content: "";
      display: block;
      margin: {
        left: auto;
        right: auto;
        top: 10px; }
      @include size(60px,2px);
      background-color: #fff; } } }

//flex
.flex {
  &.still {
    display: flex; }
  &.fw {
    flex-wrap: wrap; }
  &.ai {
    align-items: center; }
  &.jc {
    justify-content: center; }
  &.reverse {
    flex-direction: row-reverse; } }

.page-container {
  padding: 60px 30px; }

.container {
  padding: 0;
  max-width: 1100px!important;
  width: 100%;
  &.min {
    max-width: 550px; }
  @media #{$desktop} {
    width: 80%; }
  @media #{$phone} {
    max-width: 400px;
    &.min {
      max-width: 400px; } } }

.info {
  h2 {
    text-transform: capitalize;
    margin-bottom: 15px;
    &:after {
      content: "";
      display: block;
      margin-top: 10px;
      @include size(45px,2px);
      background-color: #ccc; }
    &.white {
      .en,.zh {
        @include cr(#fff); } }
    .en {
      margin-bottom: 8px;
      @include cr($color_main);
      font-size: 24px; }
    .zh {
      font-size: 18px;
      @include cr;
      font-weight: bold;
      letter-spacing: 3px; } }
  p {
    text-align: justify;
    letter-spacing: 3px;
    line-height: 25px;
    @include cr; } }

h2.title {
  display: table;
  margin: 0 auto;
  padding: 0 0 50px 0;
  text-align: center;
  .en {
    margin-bottom: 20px;
    font-size: 36px;
    text-transform: uppercase;
    @include cr($color_main);
    @include en; }
  .zh {
    display: table;
    margin: 0 auto;
    font-size: 18px;
    @include cr($color_text);
    letter-spacing: 3px;
    padding-left: 3px;
    background: linear-gradient(to top,rgba($color_main,.2) 5px,transparent 0);
    font-weight: bold; } }


.btn {
  display: table;
  min-width: 80px;
  line-height: 23px;
  text-align: center;
  padding: 0 10px;
  margin-top: 40px;
  @include cr($color_btn_hover);
  @include border(1px,solid,$color_btn_hover);
  @include t;
  &:hover {
    background-color: $color_btn_hover;
    @include cr(#fff); } }


.margin-center {
  display: table;
  margin: {
    left: auto;
    right: auto; }
  &>* {
    text-align: center; } }

#main {
  min-height: calc(100vh - 140px);
  padding-top: 64px;
  @media screen and (max-width: 768px) {
    padding-top: 52px; } }



@media #{$desktop} {
  .flex {
    display: flex; } }


.en {
  @include en; }
